// Next polyfills are required to made IE11 work.
import "core-js/es6/";
import "core-js/es7/";

import "./assets/css/fonts.css";
import "./assets/css/reset.css";
import "./assets/css/core.css";

import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {Provider} from "react-redux";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {
	FetchUser,
	Footer,
	GlobalErrors,
	HOCAnalytics,
	PrivateRoute,
	ScrollToTop,
	SecretKey,
	TranslationsData,
	Unsubscribe,
} from "./components";
import store from "./modules/store";
import NotFound from "./pages/NotFound";
import RegisterForGame from "./pages/RegisterForGame";
import ReturningUser from "./pages/ReturningUser";
import LandingScreen from "./pages/LandingScreen";
import Prizes from "./pages/Prizes";
import Help from "./pages/Help";
import GameGuidelines from "./pages/Help/GameGuidelines";
import ContactUs from "./pages/Help/ContactUs";
import FAQs from "./pages/Help/FAQs";
import GameRules from "./pages/Help/GameRules";
import PrizesPool from "./pages/Help/PrizesPool";
import Terms from "./pages/Help/Terms";
import PickEm from "./pages/PickEm";
import Leagues from "./pages/Leagues";
import Ladder from "./pages/league/Ladder";
import Users from "./pages/league/Users";
import About from "./pages/league/About";
import CreateLeague from "./pages/Leagues/CreateLeague";
import JoinLeague from "./pages/Leagues/JoinLeague";

import YourAccount from "./pages/YourAccount";
import NFLAccount from "./pages/YourAccount/NFLAccount";
import Communications from "./pages/YourAccount/Communications";
import DeactivateAccount from "./pages/YourAccount/DeactivateAccount";
import PrivacyPolicy from "./pages/Help/PrivacyPolicy";
import CollectionStatement from "./pages/Help/СollectionStatement";

let errorCount = 0;
const MAX_ERRORS = 100;
const ignoreErrors = [
	"ResizeObserver loop limit exceeded",
	"TypeError: t.googletag[n] is not a function",
	"TypeError: t.googletag[n] is not a function. (In 't.googletag[n]()', 't.googletag[n]' is undefined)",
	"API Errors",
	"Can't find variable: _AutofillCallbackHandler",
];
const ignoredWords = ["googletag"];

Sentry.init({
	dsn: "https://0a246c088b7a4bec8704ef714341f575@o151969.ingest.sentry.io/1783859",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	ignoreErrors,
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		if (event && event.message) {
			if (ignoreErrors.includes(event.message)) {
				return null;
			}

			const hasIgnored =
				ignoredWords.filter((word) => event.message?.includes(word))
					.length > 0;
			if (hasIgnored) {
				return null;
			}
		}
		return event;
	},
	allowUrls: [
		"nflpickem.co.uk",
		"nflpickem.skysports.com",
		"nfltippspiel.ran.de",
		"nflpickem.com.au",
		"canada.pickem.nfl.com",
		"nflpickem.app",
		".fanhubmedia.com",
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	sampleRate: 0.1,
});

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
			<TranslationsData>
				<FetchUser>
					<ScrollToTop>
						<HOCAnalytics>
							<Switch>
								<Route
									exact={true}
									path="/"
									component={LandingScreen}
								/>
								<Route
									path="/register-for-game"
									component={RegisterForGame}
								/>
								<Route path="/prizes" component={Prizes} />
								<Route
									exact={true}
									path="/help"
									component={Help}
								/>
								<Route
									path="/help/contact-us"
									component={ContactUs}
								/>
								<Route path="/help/faqs" component={FAQs} />
								<Route
									path="/help/game-guidelines"
									component={GameGuidelines}
								/>
								<Route
									path="/help/rules"
									component={GameRules}
								/>
								<Route
									path="/help/prizes"
									component={PrizesPool}
								/>
								<Route path="/help/terms" component={Terms} />
								<Route
									path="/help/privacy-policy"
									component={PrivacyPolicy}
								/>
								<Route
									path="/help/collection-statement"
									component={CollectionStatement}
								/>
								<Route
									path="/pick-em/:gw_id?"
									component={PickEm}
								/>
								<PrivateRoute
									exact={true}
									path="/returning-user"
									component={ReturningUser}
								/>
								<PrivateRoute
									exact={true}
									path="/leagues"
									component={Leagues}
								/>
								<PrivateRoute
									exact={true}
									path="/leagues/create"
									component={CreateLeague}
								/>
								<PrivateRoute
									exact={true}
									path="/leagues/your-leagues"
									component={Leagues}
								/>
								<PrivateRoute
									exact={true}
									path="/leagues/join"
									component={JoinLeague}
								/>
								<PrivateRoute
									exact={true}
									path="/leagues/join/:code"
									component={JoinLeague}
								/>
								<PrivateRoute
									exact={true}
									path="/league/:comp_id/ladder"
									component={Ladder}
								/>
								<PrivateRoute
									exact={true}
									path="/league/:comp_id/users"
									component={Users}
								/>
								<PrivateRoute
									exact={true}
									path="/league/:comp_id/about"
									component={About}
								/>
								<PrivateRoute
									path="/account/update"
									component={YourAccount}
								/>
								<PrivateRoute
									path="/account/nrl-account"
									component={NFLAccount}
								/>
								<PrivateRoute
									path="/account/communications"
									component={Communications}
								/>
								<PrivateRoute
									path="/account/deactivate-account"
									component={DeactivateAccount}
								/>
								<PrivateRoute
									path="/unsubscribe"
									component={Unsubscribe}
								/>
								<Route component={NotFound} />
							</Switch>
							<SecretKey />
							<Footer />
							<GlobalErrors />
						</HOCAnalytics>
					</ScrollToTop>
				</FetchUser>
			</TranslationsData>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);
